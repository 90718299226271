<template>
  <div class="foot d-flex justify-content-center align-items-center bg-white">
    <p style="font-size: 11px;font-weight: 600">Powered by Eatmol</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.foot {
  /* position: relative; */
  height: 5vh;
}
</style>
