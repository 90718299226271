import VueCookies from 'vue-cookies';

const state = {
  view: localStorage.view || 'grid',
  cart: {
    products: [],
    receiver: {
      name: '',
      contactNo: '',
      address: {},
      date: '',
      time: '',
    },
    delivery: {
      distance: 0,
      duration: 0,
      originalFee: 0.0,
      fee: 0.0,
      discount: 0.0,
    },
    discount: {
      id: '',
      percentage: 0,
      min: 0.0,
      amount: 0.0,
    },
    voucher: {
      id: '',
      code: '',
      type: '',
      min: 0.0,
      amount: 0.0,
      itemId: null,
    },
    totals: {
      subTotal: 0.0,
      taxableTotal: 0.0,
      packagingFee: 0.0,
      packagingIncremental: false,
      serviceChargeRate: localStorage.merchant
        ? (JSON.parse(localStorage.merchant).options.merchant_service_charges || 0)
        : 0,
      taxRate: localStorage.merchant
        ? (JSON.parse(localStorage.merchant).options.merchant_tax || 0)
        : 0,
      taxTotal: 0.0,
      rounding: 0.0,
    },
    tableNo: VueCookies?.get('table-no') || '',
    updated: false,
  },
  item: {
    id: null,
    name: null,
    category: [],
    quantity: 1,
    size: {
      id: null,
      name: null,
    },
    addons: [],
    isTakeaway: false,
    isServeLater: false,
    specialRemarks: null,
    price: 0.0,
    discount: 0.0,
    total: 0.0,
    nonTaxable: 1,
    updateIndex: null,
    submit: false,
  },
  service: localStorage.service,
};
const getters = {
  isGridView(state) {
    return state.view === 'grid';
  },
  isListView(state) {
    return state.view === 'list';
  },
  isDelivery(state) {
    return state.service === 'delivery';
  },
  isPickup(state) {
    return state.service === 'pickup';
  },
  isDinein(state) {
    return state.service === 'dinein';
  },
  getServiceIcons() {
    return {
      delivery: 'mdi-moped-electric',
      pickup: 'mdi-run-fast',
      dinein: 'mdi-silverware-fork-knife',
    }
  },
  getSubTotal(state, getters, rootState, rootGetters) {
    if (rootGetters['merchant/isMenuIncludeTax']) {
      return +(state.cart.totals.subTotal - getters.getTaxTotal).toFixed(2);
    } else {
      return +state.cart.totals.subTotal.toFixed(2);
    }
  },
  getTaxableTotal(state) {
    return state.cart.totals.taxableTotal;
  },
  getTaxTotal(state, getters, rootState, rootGetters) {
    if (rootGetters['merchant/isMenuIncludeTax']) {
      return +(+((state.cart.totals.taxRate / (state.cart.totals.taxRate + 100)) * state.cart.totals.taxableTotal) + ((state.cart.totals.taxRate / 100) * state.cart.delivery.fee) + ((state.cart.totals.taxRate / 100) * (rootState.merchant.profile.options.merchant_tax_packaging_charges != '2' ? getters.getPackagingFee : 0))).toFixed(2);
    } else {
      return +((state.cart.totals.taxRate / 100) * (state.cart.totals.taxableTotal + state.cart.delivery.fee) + ((state.cart.totals.taxRate / 100) * (rootState.merchant.profile.options.merchant_tax_packaging_charges != '2' ? getters.getPackagingFee : 0))).toFixed(2);
    }
  },
  getServiceCharge(state, getters, rootState) {
    const merchant_service_charges = +rootState.merchant.profile.options.merchant_service_charges;
    if (!getters.isDinein || !merchant_service_charges) {
      return 0;
    }
    
    return +((merchant_service_charges / 100) * getters.getSubTotal).toFixed(2);
  },
  getPackagingFee(state, getters) {
    if (getters.isDinein) {
      return 0;
    }

    if (!state.cart.totals.packagingIncremental) {
      return +state.cart.totals.packagingFee;
    }

    return +((getters.getQuantity * state.cart.totals.packagingFee).toFixed(2));
  },
  getDiscountTotal(state) {
    if (!state.cart.discount.id) {
      return 0;
    }

    return +((state.cart.totals.subTotal * state.cart.discount.percentage / 100).toFixed(2));
  },
  getVoucherTotal(state) {
    if (!state.cart.voucher.id) {
      return 0;
    }

    if (state.cart.voucher.type === 'product') {
      const item = state.cart.products.find(p => p.id === state.cart.voucher.itemId);
      return Math.min(+state.cart.voucher.amount, +(item?.price - item?.discount));
    } else if (state.cart.voucher.type === 'percentage') {
      return +((state.cart.totals.subTotal * state.cart.voucher.amount / 100).toFixed(2));
    } else {
      return +state.cart.voucher.amount;
    }
  },
  getRounding(state, getters) {
    const subTotalWithTax = getters.getSubTotalWithTax;

    // Adjust rounding
    let rounding = 0.0;
    const lastDigit = parseInt(
      subTotalWithTax.toFixed(2).charAt(subTotalWithTax.toFixed(2).length - 1)
    );
    switch (lastDigit) {
      case 1:
      case 2:
        rounding -= lastDigit / 100;
        break;
      case 3:
      case 4:
      case 6:
      case 7:
        rounding += (5 - lastDigit) / 100;
        break;
      case 8:
      case 9:
        rounding += (10 - lastDigit) / 100;
        break;
    }

    return rounding;
  },
  getSubTotalWithTax(state, getters) {
    return Math.max(getters.getSubTotal + getters.getTaxTotal - getters.getVoucherTotal - getters.getDiscountTotal + getters.getServiceCharge + getters.getPackagingFee + state.cart.delivery.fee, 0.0);
  },
  getTotalWithTax(state, getters) {
    return getters.getSubTotalWithTax + getters.getRounding;
  },
  getQuantity(state) {
    return state.cart.products.reduce((a, v) => a + v.quantity, 0);
  },
  getItemQuantity(state) {
    return state.item.quantity;
  },
  getItemTotal(state) {
    return (
      (state.item.price - state.item.discount) * state.item.quantity +
      state.item.addons.reduce(
        (a, c) =>
          a +
          c?.items?.reduce(
            (a2, c2) =>
              a2 + state.item.quantity * ((c2.quantity || 1) * c2.price),
            0
          ),
        0
      )
    );
  },
  getGtagItems(state) {
    return state.cart.products.map((product) => {
      return {
        id: product.id,
        name: product.name,
        category: product.category,
        price: +product.price,
        quantity: +product.quantity,
      }
    });
  },
  getTableNo(state) {
    return VueCookies?.get('table-no') || state.cart.tableNo;
  }
};
const mutations = {
  INIT_CART() {
    if (!localStorage.cart) {
      localStorage.cart = JSON.stringify({ products: [] });
    } else {
      state.cart.products = JSON.parse(localStorage.cart).products;
    }
  },
  SET_TAX_RATE(state, taxRate) {
    state.cart.totals.taxRate = +taxRate;
  },
  SET_SERVICE_CHARGE_RATE(state, serviceChargeRate) {
    state.cart.totals.serviceChargeRate = +serviceChargeRate;
  },
  SET_PACKAGING_FEE(state, { fee, incremental }) {
    state.cart.totals.packagingIncremental = incremental === '2';
    state.cart.totals.packagingFee = +fee || 0.0;
  },
  RESET_CART(state) {
    state.cart = {
      products: [],
      receiver: {
        name: '',
        contactNo: '',
        address: {},
        date: '',
        time: '',
      },
      delivery: {
        distance: 0,
        duration: 0,
        originalFee: 0.0,
        fee: 0.0,
        discount: 0.0,
      },
      discount: {
        id: '',
        percentage: 0,
        min: 0.0,
        amount: 0.0,
      },
      voucher: {
        id: '',
        code: '',
        type: '',
        min: 0.0,
        amount: 0.0,
      },
      totals: {
        subTotal: 0.0,
        taxableTotal: 0.0,
        discount: 0.0,
        voucher: 0.0,
        packagingFee: localStorage.merchant
          ? JSON.parse(localStorage.merchant).options.merchant_packaging_charge || 0.0 : 0.0,
        packagingIncremental: localStorage.merchant
          ? JSON.parse(localStorage.merchant).options.merchant_packaging_increment === '2' : false,
        serviceChargeRate: localStorage.merchant
          ? (JSON.parse(localStorage.merchant).options.merchant_service_charges || 0)
          : 0,
        taxRate: localStorage.merchant
          ? JSON.parse(localStorage.merchant).options.merchant_tax || 0
          : 0,
        taxTotal: 0.0,
        rounding: 0.0,
      },
      updated: false,
    };

    localStorage.cart = JSON.stringify({ products: [] });
  },
  SET_SERVICE(state, service) {
    localStorage.service = service;
    state.service = service;
  },
  RESET_ITEM(state) {
    state.item = {
      id: null,
      name: null,
      category: [],
      quantity: 1,
      size: {
        id: null,
        name: null,
      },
      addons: [],
      isTakeaway: false,
      isServeLater: false,
      specialRemarks: null,
      price: 0.0,
      discount: 0.0,
      total: 0.0,
      nonTaxable: 1,
      updateIndex: null,
      submit: false,
    };
  },
  SET_ITEM_DETAILS(state, { id, name, category, nonTaxable }) {
    state.item.id = id;
    state.item.name = name;
    state.item.category = category;
    state.item.nonTaxable = nonTaxable;
  },
  SET_ITEM_SIZE(state, { id, name }) {
    state.item.size.id = id;
    state.item.size.name = name;
  },
  SET_ITEM_QUANTITY(state, quantity) {
    state.item.quantity = quantity;
  },
  SET_ITEM_PRICE(state, price) {
    state.item.price = price;
  },
  SET_ITEM_DISCOUNT(state, discount) {
    state.item.discount = discount;
  },
  SET_ITEM_TAKEAWAY(state, isTakeaway) {
    state.item.isTakeaway = isTakeaway;
  },
  SET_ITEM_SERVE_LATER(state, isServeLater) {
    state.item.isServeLater = isServeLater;
  },
  SET_ITEM_SPECIAL_REMARKS(state, specialRemarks) {
    state.item.specialRemarks = specialRemarks;
  },
  SET_ITEM_ADDON(state, addons) {
    state.item.addons = addons;
  },
  SET_ITEM_UPDATE_INDEX(state, updateIndex) {
    state.item.updateIndex = updateIndex;
  },
  SET_ITEM_SUBMIT(state, submit) {
    state.item.submit = submit;
  },
  UPDATE_ITEM_ADDON(state, addonItem) {
    let isNew = true;
    let addons = [];
    for (const addon of state.item.addons) {
      // Loop Addon categories
      if (addon.id === addonItem.key) {
        // Found matched category
        if (addonItem.multiple) {
          let notFound = true;
          let items = [];
          for (const item of addon.items) {
            // Loop multiple addon item for the matched category (checkbox option)
            if (item.id === addonItem.id) {
              if (addonItem.quantity) {
                item['quantity'] = addonItem.quantity;
              }

              item['price'] = addonItem.price;
              notFound = false;
            }

            items.push(item);
          }

          if (notFound) {
            let item = {
              id: addonItem.id,
              name: addonItem.name,
              multiple: addonItem.multiple,
              price: addonItem.price,
            };

            if (addonItem.quantity) {
              Object.assign(item, { quantity: addonItem.quantity });
            }

            items.push(item);
          }

          // Sort items by id
          items.sort((a, b) => a.id - b.id);

          addons.push({
            id: addonItem.key,
            name: addonItem.keyName,
            items,
          });
        } else {
          // radio option
          addons.push({
            id: addonItem.key,
            name: addonItem.keyName,
            items: [
              {
                id: addonItem.id,
                name: addonItem.name,
                multiple: addonItem.multiple,
                price: addonItem.price,
              },
            ],
          });
        }

        isNew = false;
      } else {
        addons.push(addon);
      }
    }

    if (isNew) {
      let item = {
        id: addonItem.id,
        name: addonItem.name,
        multiple: addonItem.multiple,
        price: addonItem.price,
      };

      if (addonItem.quantity) {
        Object.assign(item, { quantity: addonItem.quantity });
      }

      addons.push({
        id: addonItem.key,
        name: addonItem.keyName,
        items: [item],
      });
    }

    // Sort addons by id
    addons.sort((a, b) => a.id - b.id);

    state.item.addons = [];
    state.item.addons = addons;
  },
  REMOVE_ITEM_ADDON(state, { key, id }) {
    state.item.addons = state.item.addons.map(x => {
      if (x.id === key) {
        x.items = x.items.filter(y => y.id !== id);
      }

      return x.items.length > 0 ? x : null;
    }).filter(a => !!a);
  },
  ADD_TO_CART(state, total) {
    state.item.total = total;

    // Update Cart Products
    let localStorageCart = localStorage.cart ? JSON.parse(localStorage.cart) : { products: [] };
    let cart = state.cart;
    if (!cart.products) {
      cart.products = [];
    }

    const _ = require('lodash');

    let foundIndex = -1;
    for (let i=0; i<cart.products.length; i++) {
      if (cart.products[i].id === state.item.id && 
            cart.products[i].size.id === state.item.size.id && 
              cart.products[i].isTakeaway === state.item.isTakeaway && 
              _.isEqual(cart.products[i].addons, state.item.addons)) {
        foundIndex = i;
        break;
      }
    }

    if (foundIndex >= 0) { // New Item is exists in cart
      if (state.item.updateIndex === foundIndex) {
        // Same item, either no change or only quantity changed
        cart.totals.subTotal -= cart.products[state.item.updateIndex].total;
        if (cart.products[state.item.updateIndex].nonTaxable == 1) {
          cart.totals.taxableTotal -= cart.products[state.item.updateIndex].total;
        }

        cart.products[state.item.updateIndex].isTakeaway = state.item.isTakeaway;
        cart.products[state.item.updateIndex].isServeLater = state.item.isServeLater;
        cart.products[state.item.updateIndex].specialRemarks = state.item.specialRemarks;
        cart.products[state.item.updateIndex].quantity = state.item.quantity;
        cart.products[state.item.updateIndex].total = state.item.total;

        localStorageCart.products[state.item.updateIndex].isTakeaway = state.item.isTakeaway;
        localStorageCart.products[state.item.updateIndex].isServeLater = state.item.isServeLater;
        localStorageCart.products[state.item.updateIndex].specialRemarks = state.item.specialRemarks;
        localStorageCart.products[state.item.updateIndex].quantity = state.item.quantity;
        localStorageCart.products[state.item.updateIndex].addons = state.item.addons;
      } else {
        // Different item
        cart.products[foundIndex].isTakeaway = state.item.isTakeaway;
        cart.products[foundIndex].isServeLater = state.item.isServeLater;
        cart.products[foundIndex].specialRemarks = state.item.specialRemarks;
        cart.products[foundIndex].quantity += state.item.quantity;
        cart.products[foundIndex].total += state.item.total;

        localStorageCart.products[foundIndex].isTakeaway = state.item.isTakeaway;
        localStorageCart.products[foundIndex].isServeLater = state.item.isServeLater;
        localStorageCart.products[foundIndex].specialRemarks = state.item.specialRemarks;
        localStorageCart.products[foundIndex].quantity += state.item.quantity;

        // Remove Existing Item
        if (state.item.updateIndex != null && cart.products[state.item.updateIndex].id === state.item.id) {
          cart.totals.subTotal -= cart.products[state.item.updateIndex].total;
          if (cart.products[state.item.updateIndex].nonTaxable == 1) {
            cart.totals.taxableTotal -= cart.products[state.item.updateIndex].total;
          }
    
          cart.products.splice(state.item.updateIndex, 1);
          localStorageCart.products.splice(state.item.updateIndex, 1);
        }
      }
    } else { // New item not exists in cart
      cart.products.push(state.item);
      localStorageCart.products.push({
        id: state.item.id,
        name: state.item.name,
        category: state.item.category,
        quantity: state.item.quantity,
        size: state.item.size,
        addons: state.item.addons.map((addon) => {
          return { ...addon, items: addon.items.map(item => _.omit(item, 'price')) };
        }),
        isTakeaway: state.item.isTakeaway,
        isServeLater: state.item.isServeLater,
        specialRemarks: state.item.specialRemarks,
      });

      // If is an update action, removed previous item
      if (state.item.updateIndex != null && cart.products[state.item.updateIndex].id === state.item.id) {
        cart.totals.subTotal -= cart.products[state.item.updateIndex].total;
        if (cart.products[state.item.updateIndex].nonTaxable == 1) {
          cart.totals.taxableTotal -= cart.products[state.item.updateIndex].total;
        }
  
        cart.products.splice(state.item.updateIndex, 1);
        localStorageCart.products.splice(state.item.updateIndex, 1);
      }
    }

    // Update Cart Total
    cart.totals.subTotal += total;
    
    // Update Taxable Total
    if (state.item.nonTaxable == 1) {
      cart.totals.taxableTotal += total;
    }

    // Update offers
    for (const offer of this.state.merchant.offers) {
      if (((state.service === 'delivery' && offer.delivery) || (state.service === 'pickup' && offer.pickup) || (state.service === 'dinein' && offer.dinein)) && cart.totals.subTotal >= offer.min) {
        cart.discount = {
          id: offer.id,
          percentage: offer.percentage,
          min: offer.min,
          amount: +((cart.totals.subTotal * offer.percentage / 100).toFixed(2)),
        }

        break;
      }
    }

    state.cart = cart;
    localStorage.cart = JSON.stringify(localStorageCart);
  },
  UPDATE_CART_ITEM_QUANTITY(state, { index, id, quantity, total }) {
    if (state.cart.products[index].id === id) {
      // Update subtotal first to avoid assign temp variable
      state.cart.totals.subTotal += total - state.cart.products[index].total;
      if (state.cart.products[index].nonTaxable == 1) {
        state.cart.totals.taxableTotal += total - state.cart.products[index].total;
      }

      // Update item details
      state.cart.products[index].quantity = quantity;
      state.cart.products[index].total = total;

      let localStorageCart = JSON.parse(localStorage.cart);
      localStorageCart.products[index].quantity = quantity;
      localStorage.cart = JSON.stringify(localStorageCart);
    }
  },
  UPDATE_CART_ITEM(state, { index, id, nonTaxable, price, discount, addonTotal }) {
    if (state.cart.products[index].id === id) {
      state.cart.products[index].price = price;
      state.cart.products[index].discount = discount;
      state.cart.products[index].nonTaxable = nonTaxable;
      state.cart.products[index].total = (state.cart.products[index].quantity * (price - discount + addonTotal));
      if (state.cart.products[index].nonTaxable == 1) {
        state.cart.totals.taxableTotal += (state.cart.products[index].total || 0.0);
      }
      state.cart.totals.subTotal += state.cart.products[index].total;

      // Update offers
      for (const offer of this.state.merchant.offers) {
        if (((state.service === 'delivery' && offer.delivery) || (state.service === 'pickup' && offer.pickup) || (state.service === 'dinein' && offer.dinein)) && state.cart.totals.subTotal >= offer.min) {
          state.cart.discount = {
            id: offer.id,
            percentage: offer.percentage,
            min: offer.min,
            amount: +((state.cart.totals.subTotal * offer.percentage / 100).toFixed(2)),
          }

          break;
        }
      }
    }
  },
  REMOVE_CART_ITEM(state, { index, id }) {
    if (state.cart.products[index].id === id) {
      state.cart.totals.subTotal -= (state.cart.products[index].total || 0.0);
      if (state.cart.products[index].nonTaxable == 1) {
        state.cart.totals.taxableTotal -= (state.cart.products[index].total || 0.0);
      }

      state.cart.products.splice(index, 1);

      let localStorageCart = JSON.parse(localStorage.cart);
      localStorageCart.products.splice(index, 1);
      localStorage.cart = JSON.stringify(localStorageCart);
    }
  },
  SET_RECEIVER_DATE(state, date) {
    state.cart.receiver.date = date;
  },
  SET_RECEIVER_TIME(state, time) {
    state.cart.receiver.time = time;
  },
  SET_DELIVERY_DETAILS(state, { distance, duration }) {
    state.cart.delivery.distance = distance;
    state.cart.delivery.duration = duration;
  },
  SET_DELIVERY_FEE(state, originalFee) {
    state.cart.delivery.originalFee = originalFee;
    state.cart.delivery.fee = originalFee;
  },
  SET_DELIVERY_DISCOUNT(state, discount) {
    state.cart.delivery.fee = Math.max((state.cart.delivery.originalFee - discount), 0);
    state.cart.delivery.discount = Math.max((state.cart.delivery.originalFee - state.cart.delivery.fee), 0);
  },
  SET_DISCOUNT(state, { id, percentage, min, amount }) {
    state.cart.discount.id = id;
    state.cart.discount.percentage = percentage;
    state.cart.discount.min = min;
    state.cart.discount.amount = amount;
  },
  SET_VOUCHER(state, { id, code, type, amount, min_purchase, item }) {
    state.cart.voucher.id = id;
    state.cart.voucher.code = code;
    state.cart.voucher.type = type;
    state.cart.voucher.amount = amount;
    state.cart.voucher.min = min_purchase;
    state.cart.voucher.itemId = item?.id;
    // localStorage.cart = JSON.stringify(state.cart);
  },
  REMOVE_VOUCHER(state) {
    state.cart.voucher = {
      id: '',
      code: '',
      type: '',
      amount: 0.0,
      min: 0.0,
      itemId: null,
    }
    // localStorage.cart = JSON.stringify(state.cart);

    // Clear merchant vouchers state
    this.commit('merchant/SET_MERCHANT_VOUCHERS', null, { root: true });
  },
  SET_TABLE_NO(state, tableNo) {
    state.cart.tableNo = tableNo;
  },
  SET_VIEW(state, view) {
    state.view = view;
    localStorage.view = view;
  },
};
const actions = {};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
