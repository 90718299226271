<template>
  <div>
    <v-app id="mainapp">
      <Drawer
        :visible="open"
        :installable="deferredPrompt || (!isIos && !isBrowserSupported)"
        v-on:install="installPWA($event)"
        v-on:close-drawer="closeDrawer()"
      >
      </Drawer>
      <transition name="slide-x-transition">
        <router-view
          :installable="deferredPrompt || (!isIos && !isBrowserSupported)"
          v-on:install="installPWA($event)"
          v-on:open-drawer="openDrawer()"
          v-on:close-drawer="closeDrawer()"
        />
      </transition>
      <iframe
        src="https://sso.eatmol.com"
        id="sso"
        style="width: 0px; height: 0px; position: relative; left: -500px;"
      ></iframe>
      <div v-html="profile.options.merchant_facebook_pixel"></div>
      <v-alert
        class="alert offline text-overline font-weight-6 d-flex justify-content-center p-2"
        color="secondary"
        icon="mdi-cloud-off-outline"
        type="warning"
        transition="slide-y-reverse-transition"
        dense
        v-if="isOffline"
        >No internet connection</v-alert
      >
      <v-overlay v-if="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <notify-unsupported-browser-modal :visible="showUnsupportedBrowser" source="pwa" v-on:close="showUnsupportedBrowser = false"></notify-unsupported-browser-modal>
      <notify-update-modal :version="version" v-if="version && version.changelog && hasUpdate"></notify-update-modal>
    </v-app>
  </div>
</template>

<script>
const Drawer = () => import(/* webpackPrefetch: true */'@/components/partials/SideDrawer');
const NotifyUnsupportedBrowserModal = () => import(/* webpackPrefetch: true */'@/components/DialogModals/NotifyUnsupportedBrowserModal');
const NotifyUpdateModal = () => import(/* webpackPrefetch: true */'@/components/DialogModals/NotifyUpdateModal');
import { mapActions, mapMutations, mapState } from 'vuex';
import { setOptions, bootstrap } from 'vue-gtag';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.profile.restaurant_name,
      titleTemplate: null,
      meta: [
        { name: 'og:url', property: 'og:url', content: `https://${this.profile.restaurant_hostname}` },
        { name: 'og:site_name', property: 'og:site_name', content: this.profile.restaurant_name },
        { name: 'og:title', property: 'og:title', content: `${this.profile.restaurant_name}` },
        { name: 'og:description', property: 'og:description', content: `${this.profile.restaurant_name}` },
        { name: 'og:image', property: 'og:image', content: this.getMerchantLogo() },
        { name: 'og:type', property: 'og:type', content: 'website' },
        { name: 'og:updated_time', property: 'og:updated_time', content: Date.now() },
      ],
    }
  },
  components: {
    Drawer,
    NotifyUnsupportedBrowserModal,
    NotifyUpdateModal,
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      open: false,
      version: null,
      hasUpdate: false,
      isOffline: false,
      isLoading: false,
      deferredPrompt: null,
      showUnsupportedBrowser: false,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    isBrowserSupported: function() {
      return !(/(.browser|.fbav|.micromessenger(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent) && (/(opera|chrome|safari|firefox|msie(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent);
    },
    isIos: function() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    },
    isStandaloneMode: function() {
      return ('standalone' in window.navigator) && (window.navigator.standalone);
    },
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('merchant', [
      'getMerchantByHostname', 
    ]),
    ...mapActions('notification', [
      'initializeFirebase', 
      'requestFirebasePermission',
    ]),
    ...mapMutations('auth', ['SET_TOKEN']),
    ...mapMutations("cart", ['SET_VOUCHER']),
    ...mapMutations('customer', ['SET_CUSTOMER']),
    getMerchantLogo() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
    openDrawer() {
      this.open = true;
      window.isDrawerOpen = true;
    },
    closeDrawer() {
      this.open = false;
      setTimeout(() => window.isDrawerOpen = false, 100);
    },
    async checkUpdate() {
      this.version = JSON.parse(await this.getAdminOption({
        key: 'version',
      }));
      const localVersion = JSON.parse(localStorage.version || '{}');

      if (!localVersion.updatedAt || dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))) {
        localStorage.removeItem("cart");
        localStorage.version = JSON.stringify(this.version);

        if (dayjs(this.version.updatedAt).isAfter(dayjs(localVersion.updatedAt))) {
          this.hasUpdate = true;
        }
      }
    },
    ssoHandler(event) {
      const { action, value } = event.data;
      if (action === 'authenticated') {
        this.SET_TOKEN(value.token);
        this.SET_CUSTOMER(value.customer);
      } else if (action === 'sync-uuid') {
        localStorage.uuid = value;
      } else if (action === 'voucher-applied') {
        this.SET_VOUCHER({
          id: value.id, 
          code: value.code, 
          type: value.type, 
          amount: value.amount, 
          min_purchase: value.minPurchase,
          item: value.item,
        });
      }
    },
    autoUUID() {
      const iframe = document.getElementById('sso').contentWindow;
      iframe.postMessage({
        action: 'auto-uuid'
      }, '*');
    },
    autoLogin() {
      const iframe = document.getElementById('sso').contentWindow;
      iframe.postMessage({
        action: 'auto-login'
      }, '*');
    },
    installPWA(source) {
      this.$gtag.event('install-pwa', {
        'event_category': 'interaction',
        'event_label': 'Install PWA',
        'event_value': source,
      });

      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choice) => {
          if (choice.outcome === 'accepted') {
            // Do something additional if the user chose to install
            this.$gtag.event('install-pwa-accepted', {
              'event_category': 'engagement',
              'event_label': 'Accepted Install PWA',
              'event_value': source,
            });
          } else {
            // Do something additional if the user declined
            this.$gtag.event('install-pwa-declined', {
              'event_category': 'engagement',
              'event_label': 'Declined Install PWA',
              'event_value': source,
            });
          }
          this.deferredPrompt = null;
        });
      } else if (!this.isIos && !this.isBrowserSupported) {
        this.$gtag.event('unsupported-browser', {
          'event_category': 'experience',
          'event_label': 'Unsupported Browser (PWA)',
          'event_value': 'pwa',
        });

        this.showUnsupportedBrowser = true;
      }
    },
    autoApplyVoucher() {
      const iframe = document.getElementById('sso').contentWindow;
      iframe.postMessage({
        action: 'auto-apply-voucher',
        value: location.hostname,
      }, '*');
    },
    onOffline () {
      this.isOffline = true;
    },
    onOnline() {
      this.isOffline = false;
    },
  },
  async beforeCreate() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      this.deferredPrompt = e;
    });
  },
  async created() {
    dayjs.extend(isSameOrAfter);

    if (!this.isBrowserSupported) {
      window.location.href = `googlechrome://${window.location.href.replace('https://', '')}`;
    }

    // Check any new update
    await this.checkUpdate();
    
    // Get merchant profile
    await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);

    // set default cookie config
    this.$cookies.config('3h');

    setOptions({
      config: {
        id: this.profile?.options.merchant_google_analytics,
        params: {
          send_page_view: false
        }
      },
      appName: 'EatMol',
      pageTrackerScreenviewEnabled: true,
    });

    bootstrap();

    this.$gtag.customMap({ 'dimension1': 'event_category' });
    this.$gtag.customMap({ 'dimension2': 'event_label' });
    this.$gtag.customMap({ 'dimension3': 'value' });

    // Set Gtag currency
    this.$gtag.set({
      'country': 'MY',
      'currency': 'MYR',
    });

    window.addEventListener('message', this.ssoHandler);

    if (!localStorage.uuid) {
      setTimeout(() => this.autoUUID(), 500);
    }

    if (!localStorage.token) {
      setTimeout(() => this.autoLogin(), 500);
    }

    // Attempt to auto apply voucher if any
    this.autoApplyVoucher();

    window.addEventListener('appinstalled', () => {
      this.$gtag.event('pwa-installed', {
        'event_category': 'engagement',
        'event_label': 'PWA Installed',
      });
    });

    window.addEventListener('offline', this.onOffline);
    window.addEventListener('online', this.onOnline);

    if (!this.isIos) {
      // this.initializeFirebase();
      // await this.requestFirebasePermission();

      if (this.isStandaloneMode) {
        await window.screen.orientation.lock('portrait');
      }
    }
  },
}
</script>
<style>
.mobiframe {
  border: 1px solid #cbcbcb;
  width: 414px;
  height: 100vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.app-title {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}
.app-content {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}
.alert.offline {
  position: fixed;
  left: 5%;
  right: 5%;
  bottom: 0px;
  z-index: 999;
  width: 90%;
}
.alert.offline .v-alert__wrapper .v-icon,
.alert.offline .v-alert__wrapper .v-alert__content {
  align-self: center;
}
input.input-voucher {
  font-size: 16px !important;
}
input.input-voucher::-webkit-input-placeholder { /* WebKit browsers */
  font-size: .75rem !important;
}

input.input-voucher:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: .75rem !important;
}

input.input-voucher::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: .75rem !important;
}

input.input-voucher:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: .75rem !important;
} 
@media screen and (max-width: 1023px) {
  .mobiframe {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  #mainapp {
    /* display: none; */
  }

  .alert.offline {
    width: 374px;
    left: calc(50% - 187px);
  }
}
</style>
