import customersApi from '../../api/customers';

const state = {
  token: localStorage.token,
  authRedirect: localStorage.authRedirect,
};

const getters = {
  getRedirect() {
    return state.authRedirect || localStorage.authRedirect;
  },
  getToken(state) {
    return state.token || localStorage.token;
  },
  isLoggedIn(state) {
    return state.token ? true : false;
  },
};

const mutations = {
  SET_REDIRECT(state, redirect) {
    if (redirect) {
      localStorage.authRedirect = redirect;
    } else {
      localStorage.removeItem('authRedirect');
    }
  },
  SET_TOKEN(state, token) {
    if (token) {
      localStorage.token = token;
      state.token = token;
    } else {
      localStorage.removeItem('token');
      state.token = null;
    }
  },
};

const actions = {
  async authenticate(context, { phoneNo, email, firstName, lastName, avatar, password, method }) {
    const res = await customersApi.login({
      phoneNo,
      email,
      firstName,
      lastName,
      avatar,
      password,
      method,
    });

    if (res.status != 200) {
      throw res.data;
    }

    context.commit('SET_TOKEN', res.data.token);
    context.commit('customer/SET_CUSTOMER', res.data.data, { root: true });

    const iframe = document.getElementById('sso').contentWindow;
    iframe.postMessage({
      action: 'login',
      value: {
        token: res.data.token,
        customer: res.data.data,
      },
    }, '*');

    return res.data.data;
  },
  async resetPassword(context, { phoneNo, email }) {
    const res = await customersApi.resetPassword({
      phoneNo,
      email,
    });

    return res;
  },
  logout(context, callback) {
    localStorage.removeItem('token');
    context.commit('SET_TOKEN', null);
    context.commit('customer/SET_CUSTOMER', null, { root: true });

    const iframe = document.getElementById('sso').contentWindow;
    iframe.postMessage({
      action: 'logout',
    }, '*');

    callback();
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  getters,
  actions,
};
