import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueMeta from 'vue-meta'
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './routes';
import vuetify from './plugins/vuetify';
import VCalendar from 'v-calendar';
import VueGtag from 'vue-gtag';
import { store } from '@/store';
import { extend } from 'vee-validate';
import { required, email, min, max, regex, confirmed } from 'vee-validate/dist/rules';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/main.css';
import './registerServiceWorker';

require('dotenv').config();

// Add the required rule
extend('required', {
  ...required,
  message: 'This field is required',
});

// Add the email rule
extend('email', {
  ...email,
  message: 'This field must be a valid email',
});

// Add the min rule
extend('min', {
  ...min,
  message: 'This field min length is {length}',
});

// Add the max rule
extend('max', {
  ...max,
  message: 'This field max length is {length}',
});

// Add the regex rule
extend('regex', {
  ...regex,
});

// Add the confirmed rule
extend('confirmed', {
  ...confirmed,
  message: 'Password not match',
});

Vue.config.productionTip = false;

Vue.filter('capitalize', function(value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

const numberFormats = {
  'en-MY': {
    currency: {
      style: 'currency',
      currency: 'MYR',
      currencyDisplay: 'symbol'
    },
  },
};

Vue.use(VueCookies);
Vue.use(VueMeta);
Vue.use(VueGtag, {
  bootstrap: false
}, router);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueI18n);
const i18n = new VueI18n({
  numberFormats,
  i18n: {
    locale: 'en',
    fallbackLocale: 'en',
    localeDir: 'locales',
    enableInSFC: false,
    enableLegacy: false,
  },
});

router.beforeEach((to, from, next) => {
  var user = localStorage.getItem('user');
  if (user) {
    store.commit('auth/Login', JSON.parse(user));
    // next();
  }

  if (to.matched.some(record => record.meta.redirectIfLoggedIn)) {
    if (store.state.auth.user) {
      next({ name: 'home' });
    } else {
      next();
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth) && (to.matched.some(record => record.name !== 'checkout') || store.state.merchant.profile.workflowCustomerLogin !== 'guest')) {
    if (store.state.auth.token && store.state.customer.customer) {
      next();
    } else if (to.matched.some(record => record.name !== 'checkout')) {
      next({ name: 'login', query: { redirectFullPath: to.path } });
    } else if (VueCookies.get('isGuest')) {
      next();
    } else {
      next({ name: 'login', query: { redirectFullPath: to.path } });
    }
  } else {
    next();
  }

  if (to.matched.some(record => record.name === 'dinein')) {
    if (!to?.params?.tableNo) {
      next({ name: 'scan' });
    } else {
      next();
    }
  }
});

new Vue({
  render: h => h(App),
  i18n,
  vuetify,
  store,
  router: router,
}).$mount('#app');
